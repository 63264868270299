<template>
  <b-nav-item-dropdown
    class="dropdown-user nomarker"
    left
    menu-class="w-auto py-0"
    toggle-class="d-flex align-items-center dropdown-user-link"
  >
    <template #button-content>
      <b-button
        size="sm"
        variant="primary"
      >
        <div class="d-flex flex-column align-items-start">
          <span class="font-small-1 my-25 text-light">Viewing As</span>
          <h6 class="text-white mb-0 font-small-3">
            <span class="mb-0">
              {{ selectedOrg.title }}
            </span>
            <b-badge
              class="text-primary px-25"
              variant="light"
            >
              {{ roleDefinitions[currentRole].displayName }}
            </b-badge>
          </h6>
        </div>
      </b-button>
    </template>
    <div class="search-bar p-25 border-bottom">
      <b-form-input
        v-model="searchQuery"
        class="border-0 bg-light"
        placeholder="Search..."
        size="sm"
        type="text"
      />
    </div>
    <div class="dropdown-menu-content">
      <b-dropdown-item
        v-for="organization in filteredOrgs"
        :key="organization.id"
        link-class="text-wrap"
        @click="switchToOrg(organization)"
      >
        <span class="font-small-3 text-nowrap">
          {{ organization.title }} <b-badge
            variant="light-primary"
          >
            {{ roleDefinitions[organization.role].displayName }}
          </b-badge>
        </span>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>
<script>
import {
  BNavItemDropdown, BDropdownItem, BBadge, BButton, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import { roleDefinitions } from '@/constants/acl-roles'

export default {
  name: 'RoleSwitcher',
  components: {
    BDropdownItem,
    BNavItemDropdown,
    BBadge,
    BButton,
    BFormInput,
  },
  data() {
    return {
      searchQuery: null,
    }
  },
  computed: {
    roleDefinitions() {
      return roleDefinitions
    },
    ...mapGetters({
      userData: 'user/getUserData',
      selectedOrg: 'user/getSelectedOrgDetails',
      currentRole: 'user/getCurrentRole',
      associatedOrgs: 'user/getAssociatedOrgDetails',
    }),
    filteredOrgs() {
      if (!this.searchQuery) return this.associatedOrgs
      return this.associatedOrgs.filter(org => org.title?.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
  },
  methods: {
    setFilter(role) {
      if (this.filterRole === role) this.filterRole = null
      else this.filterRole = role
      return true
    },
    switchToOrg(org) {
      store.dispatch('user/setSelectedOrg', org)
      return true
    },
  },
}
</script>
<style scoped>
.dropdown-menu-content {
  max-height: min(18.5rem, 80vh);
  overflow-y: auto;
}

.dropdown-menu-content li {
  white-space: nowrap;
}
</style>
