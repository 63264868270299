<template>
  <b-nav-item-dropdown
    class="dropdown-user"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userDetails.fullName ? userDetails.fullName.split(' ')[0] : userDetails.username || '' }}
        </p>
        <span class="user-status">{{ currentRole.role }}</span>
      </div>
      <b-avatar
        badge
        badge-variant="success"
        class="badge-minimal"
        size="40"
        variant="light-primary"
      >
        <feather-icon
          v-if="!userDetails.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'account-settings'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        class="mr-50"
        icon="UserIcon"
        size="16"
      />
      <span>Account</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      target="_blank"
      href="https://forms.gle/rrceubX8tnGJKQGh6"
    >
      <feather-icon
        class="mr-50"
        icon="LifeBuoyIcon"
        size="16"
      />
      <span>Contact Us</span>
    </b-dropdown-item>
    <dark-Toggler />
    <b-dropdown-item
      link-class="d-flex align-items-center"
      variant="danger"
      @click="logout"
    >
      <feather-icon
        class="mr-50"
        icon="LogOutIcon"
        size="16"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BAvatar, BDropdownItem, BNavItemDropdown } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { clearSession } from '@/utils/auth'
import { mapGetters } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    DarkToggler,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapGetters({
      userDetails: 'user/getUserDetails',
      currentRole: 'user/getCurrentRole',
    }),
  },
  methods: {
    logout: clearSession,
  },
}
</script>
